import React, { useState } from 'react';
import menuClose from '../images/icon-cross.svg';
import '../scss/PhotoLightbox.scss';

const PhotoLightbox = ({ image }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div key={image.id} className={`lightbox ${isOpen ? 'open' : ''}`} onClick={handleClick}>
      <div className='close'><img src={menuClose} width={48} height={48} alt="Close"/></div>
      <img src={image.src} alt={image.title} />
      <div className="title">{image.title}</div>
    </div>
  );
};

export default PhotoLightbox;
