import { Link } from "react-router-dom";
import logo from '../images/villa-kroeze-logo-header.webp';
import logoMob from '../images/villa-kroeze-logo-mobile.webp';
import Nav from './Nav'

function Header({ language }) {

    const lang = language !== 'nl' ? language : '';

    return (

        <header>
            <Link to={"/" + lang}><img className="header-logo" src={logo} alt="Villa Kroeze" width={267} height={200} /><img className="mobile-logo" src={logoMob} alt="Villa Kroeze" width={120} height={90} /></Link>
            <Nav language={language} />
        </header>

    );
}

export default Header;