import React from "react";
import photo1 from '../images/photo-featured.webp';
import { langFiles as languageFiles } from '../parts/LanguageSwitcher';
 
function Home({ language }) {

    const content = languageFiles[language];

    return (
        <section className="villa-description">
            <img className="home-featured" src={photo1} width={450} height={300} alt='featured' />

            <h2>Villa<span> Kroeze</span></h2>

            <p>{content.description1}</p>

            <p>{content.description2}</p>

            <p>{content.description3}</p>

        </section>);
}

export default Home;