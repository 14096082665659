import React, { useEffect, useState } from 'react';
import '../scss/Reviews.scss';
import star from '../images/Icon-review-star-72x72.webp';
import { langFiles as languageFiles } from '../parts/LanguageSwitcher';

function ReviewComponent({language}) {

  const [reviews, setReviews] = useState([]);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [reviews.length]);

  useEffect(() => {
    const getPlaceReviews = () => {
      const placeId = 'ChIJIdJcJxRb0i0RNB2CAELDctg';
      const service = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );

      service.getDetails(
        {
          placeId,
          fields: ['reviews']
        },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const placeReviews = place.reviews || [];
            const filteredReviews = placeReviews.filter((review) => review.text);
            setReviews(filteredReviews);
          } else {
            console.error('Fout bij het ophalen van beoordelingen:', status);
          }
        }
      );
    };

    getPlaceReviews();
  }, []);

  const content = languageFiles[language];

  return (

    <div className="reviews-section">
      <div className='reviews-carousel'>
        {reviews.map((review, index) => (
          <div key={index} className={`review ${index === currentIndex ? 'active' : ''}`}>
            <div className="review-content">
              <img className="reviewer-avatar" src={review.profile_photo_url} alt="Reviewer Avatar" />
              <div className="review-rating">
                <h3>{review.author_name} {content.wrote_a}<br />{review.rating} {content.Star_Review_on_Google}</h3>
                {[...Array(review.rating)].map((_, i) => (
                  <img key={i} className="star-image" src={star} alt="Star" />
                ))}
              </div>
            </div>
            <p className="review-text">"{review.text}"</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ReviewComponent;
