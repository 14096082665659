import React from "react";
import ReviewComponent from '../parts/ReviewComponent';
import RatingComponent from '../parts/RatingComponent';
import { langFiles as languageFiles } from '../parts/LanguageSwitcher';
 
function Reviews({language}) {

    const content = languageFiles[language];

    return (
        <section className="villa-revews">
            <h2>Villa<span> {content.Reviews}</span></h2>
            <ReviewComponent language={language} />
            <RatingComponent language={language} />
        </section>);
}

export default Reviews;
