import React, { useEffect, useState } from 'react';
import star from '../images/Icon-review-star-72x72.webp';
import '../scss/Rating.scss';
import { langFiles as languageFiles } from '../parts/LanguageSwitcher';

function RatingComponent({language}) {
    const [rating, setRating] = useState();
    const [totalRatings, setTotalRatings] = useState();

    useEffect(() => {
        const getPlaceReviews = () => {
            const placeId = 'ChIJIdJcJxRb0i0RNB2CAELDctg';
            const service = new window.google.maps.places.PlacesService(
                document.createElement('div')
            );

            service.getDetails(
                {
                    placeId,
                    fields: ['rating', 'user_ratings_total']
                },
                (place, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        const placeRating = place.rating;
                        setRating(placeRating);
                        const placeTotalRatings = place.user_ratings_total;
                        setTotalRatings(placeTotalRatings);
                    } else {
                        console.error('Fout bij het ophalen van beoordelingen:', status);
                    }
                }
            );
        };

        getPlaceReviews();
    }, []);

    const content = languageFiles[language];

    return (
        <div className='google-rating'>
            <div className='logo'></div>
            <h2><span>Villa Kroeze </span>{rating},0</h2>
            <p> {[...Array(rating)].map((_, i) => (
                <img key={i} className="star-image" src={star} alt="Star" />
            ))}
                <br />({totalRatings} {content.Reviews})<br />
                <br /><a href='https://g.page/r/CTQdggBCw3LYEB0/review' target='_blank' rel='noopener noreferrer'>{content.Write_a_review}</a></p>
        </div>
    );
}

export default RatingComponent;

