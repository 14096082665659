import { useState } from 'react';
import { Link } from "react-router-dom";
import LanguageSwitcher,{ langFiles as languageFiles } from './LanguageSwitcher';
import menuBurger from '../images/icon-burger.svg';
import menuClose from '../images/icon-cross.svg';

function Nav({ language }) {
  const content = languageFiles[language];
  
  const lang = language !== 'nl' ? language : ''; 

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleMenuItemClick = () => {
    setIsOpen(false);
    window.scrollTo(0, 0);
  };

  return (
    <nav className={`${isOpen ? 'open' : ''}`}>
      <button className="menu-toggle" onClick={toggleMenu}><img src={`${isOpen ? menuClose : menuBurger}`} width={48} height={48} alt="menu"/></button>
      <ul className={`${isOpen ? 'open' : ''}`}>
        <li><Link to={"/" + lang } onClick={handleMenuItemClick} >{content.home}</Link></li>
        <li><Link to={"/" + language + "/photos"} onClick={handleMenuItemClick}>{content.photos}</Link></li>
        <li><Link to={"/" + language + "/reviews"} onClick={handleMenuItemClick}>{content.Reviews}</Link></li>
        <li><LanguageSwitcher language={language} /></li>
      </ul>
    </nav>
  );
}

export default Nav;
