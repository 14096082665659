import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from '../pages/Home';
import Photos from '../pages/Photos';
import Reviews from '../pages/Reviews';

function Main({ language }) {

  return (
    <main className="villa-kroeze">
      <Routes>
        <Route path="/" element={<Home language={language} />}  />
        <Route path="/photos" element={<Photos language={language} />} />
        <Route path="/reviews" element={<Reviews language={language} />} />
      </Routes>
    </main>
  );
}

export default Main;
